import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ButtonGroupItem, hasActiveButtonItem } from '../../../../model/button-group-item';
import { UppComponentNames } from '../../../../service/model';
import { CodeshareInformationsUi, JourneyUiCarrierScope } from '../../../model';
import { flightRangesRegexPattern } from '../../../../service/model/common/validators';
import { FLIGHT_RANGES_VALIDATION_MSG, SECTION_VALIDATION_MESSAGES } from '../../../../service/model/common/validation-messages';

@Component({
  selector: 'ama-ng-upp-flight-details-carrier',
  templateUrl: './flight-details-carrier.component.html'
})
export class FlightDetailsCarrierComponent implements OnChanges {
  @Input() carrierFormGroup: UntypedFormGroup;
  @Input() carrierScope: JourneyUiCarrierScope;
  @Input() readonly = true;
  @Input() hasNDCContentType = false;
  @Input() showFamiliesV2 = false;
  @Input() flightDetailsId?: string;

  UppComponentNames = UppComponentNames;
  SECTION_VALIDATION_MESSAGES = SECTION_VALIDATION_MESSAGES;
  FLIGHT_RANGES_VALIDATION_MSG = FLIGHT_RANGES_VALIDATION_MSG;

  segmentPositions = ['-5', '-4', '-3', '-2', '-1', '1', '2', '3', '4', '5'];

  carrierScopeButtonGroupItems: ButtonGroupItem[];
  carrierScopeButtonGroupMap: { [key: string]: ButtonGroupItem };

  codeshareScopeButtonGroupItems: ButtonGroupItem[];
  codeshareScopeButtonGroupMap: { [key: string]: ButtonGroupItem };

  showSegmentPositions = false;

  uid = Date.now() + Math.random();

  labels: { [key: string]: string };

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.labels = {
      primeCarrier: $localize`:@@upp.global.criteria.primeCarrier.label:Prime carrier`,
      primeAlliance: $localize`:@@upp.global.criteria.primeAlliance.label:Prime alliance`,
      operatingCarrier: $localize`:@@upp.global.criteria.operatingCarrier.label:Operating carrier`,
      operatingAlliance: $localize`:@@upp.global.criteria.operatingAlliance.label:Operating alliance`,
      marketingCarrier: $localize`:@@upp.global.criteria.marketingCarrier.label:Marketing carrier`,
      marketingAlliance: $localize`:@@upp.global.criteria.marketingAlliance.label:Marketing alliance`
    };
  }

  get hasCarrierScope(): AbstractControl {
    return this.carrierFormGroup.get('hasCarrierScope');
  }

  get carrierInformations(): AbstractControl {
    return this.carrierFormGroup.get('carrierInformations');
  }

  get hasCodeshareScope(): AbstractControl {
    return this.carrierFormGroup.get('hasCodeshareScope');
  }

  get connectionType(): AbstractControl {
    return this.carrierFormGroup.get('connectionType');
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.carrierScopeButtonGroupItems = this.generateCarrierScopeButtonGroupItems();
    this.carrierScopeButtonGroupMap = this.buttonGroupItemsToMap(this.carrierScopeButtonGroupItems);

    this.codeshareScopeButtonGroupItems = this.generateCodeshareScopeButtonGroupItems();
    this.codeshareScopeButtonGroupMap = this.buttonGroupItemsToMap(this.codeshareScopeButtonGroupItems);

    this.carrierFormGroup.setControl('hasCarrierScope', this.formBuilder.control(false, Validators.requiredTrue));

    this.initializeSections();
  }

  onCarrierScopeToggle(item: ButtonGroupItem): void {
    if (item.active) {
      if (item.name === 'airlineInfo') {
        this.carrierFormGroup.setControl('carrierInformations', this.formBuilder.group({}));
      } else if (item.name === 'codeshare') {
        this.carrierFormGroup.setControl('hasCodeshareScope', this.formBuilder.control(false, Validators.requiredTrue));
        this.carrierFormGroup.setControl('codeshareInformations', this.formBuilder.group({}));
      } else if (item.name === 'flightRanges') {
        this.carrierFormGroup.setControl(
          'flightRanges',
          this.formBuilder.control({ value: this.carrierScope?.flightRanges, disabled: this.readonly }, [
            Validators.required,
            Validators.pattern(flightRangesRegexPattern)
          ])
        );
      } else if (item.name === 'connectionType') {
        this.carrierFormGroup.setControl(
          'connectionType',
          this.formBuilder.control(
            { value: this.carrierScope?.connectionType, disabled: this.readonly },
            Validators.required
          )
        );
      }
    } else {
      if (item.name === 'airlineInfo') {
        this.carrierFormGroup.removeControl('carrierInformations');
        delete this.carrierScope?.carrierInformations;
      } else if (item.name === 'codeshare') {
        this.resetGroupActiveFlag(this.codeshareScopeButtonGroupItems);
        this.carrierFormGroup.removeControl('codeshareInformations');
        this.carrierFormGroup.removeControl('hasCodeshareScope');
        delete this.carrierScope?.codeshareInformations;
      } else if (item.name === 'flightRanges') {
        this.carrierFormGroup.removeControl('flightRanges');
        delete this.carrierScope?.flightRanges;
      } else if (item.name === 'connectionType') {
        this.carrierFormGroup.removeControl('connectionType');
        delete this.carrierScope?.connectionType;
      }
    }

    this.hasCarrierScope.setValue(hasActiveButtonItem(this.carrierScopeButtonGroupItems));
    this.hasCarrierScope.markAsTouched();

    this.updateShowSegmentPositions();
  }

  onCodeshareScopeToggle(item: ButtonGroupItem): void {
    const operatingAndMarketingDisabled = this.codeshareScopeButtonGroupMap.primeData.active ? true : this.readonly;
    this.codeshareScopeButtonGroupMap.operatingCarrierData.disabled = operatingAndMarketingDisabled;
    this.codeshareScopeButtonGroupMap.marketingCarrierData.disabled = operatingAndMarketingDisabled;

    const primeDisabled =
      this.codeshareScopeButtonGroupMap.operatingCarrierData.active ||
      this.codeshareScopeButtonGroupMap.marketingCarrierData.active
        ? true
        : this.readonly;
    this.codeshareScopeButtonGroupMap.primeData.disabled = primeDisabled;

    const codeshareInformationsFormGroup: UntypedFormGroup = this.carrierFormGroup.get(
      'codeshareInformations'
    ) as UntypedFormGroup;

    if (item.active) {
      codeshareInformationsFormGroup.setControl(item.name, this.formBuilder.group({}));
      this.changeDetectorRef.detectChanges();
    } else {
      codeshareInformationsFormGroup.removeControl(item.name);
      delete this.carrierScope?.codeshareInformations?.[item.name as keyof CodeshareInformationsUi];
    }
    this.hasCodeshareScope.setValue(hasActiveButtonItem(this.codeshareScopeButtonGroupItems));
    this.hasCodeshareScope?.markAsTouched();
  }

  private generateCarrierScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: 'airlineInfo',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.airlineInfo.label:Airline info`
      },
      {
        name: 'codeshare',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.codeshare.label:Codeshare`
      },
      {
        name: 'flightRanges',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.flightNoOrRange.label:Flight no. or range`
      },
      {
        name: 'connectionType',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.connectionType.label:Connection type`
      }
    ];
  }

  private generateCodeshareScopeButtonGroupItems(): ButtonGroupItem[] {
    return [
      {
        name: 'primeData',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.prime.label:Prime`
      },
      {
        name: 'operatingCarrierData',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.operating.label:Operating`
      },
      {
        name: 'marketingCarrierData',
        active: false,
        disabled: this.readonly,
        title: $localize`:@@upp.global.criteria.marketing.label:Marketing`
      }
    ];
  }

  private buttonGroupItemsToMap(buttonGroupItems: ButtonGroupItem[]): { [key: string]: ButtonGroupItem } {
    return buttonGroupItems.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
  }

  private resetGroupActiveFlag(buttonGroupItems: ButtonGroupItem[]): void {
    buttonGroupItems.forEach((buttonGroupItem) => {
      buttonGroupItem.active = false;
      buttonGroupItem.disabled = this.readonly;
    });
  }

  private updateShowSegmentPositions(): void {
    this.showSegmentPositions = !this.hasNDCContentType &&
      (this.carrierScopeButtonGroupMap.airlineInfo.active ||
      this.carrierScopeButtonGroupMap.codeshare.active ||
      this.carrierScopeButtonGroupMap.flightRanges.active);
    if (this.showSegmentPositions) {
      this.carrierFormGroup.addControl(
        'segmentPositions',
        this.formBuilder.control({ value: this.carrierScope?.segmentPositions, disabled: this.readonly })
      );
    } else {
      this.carrierFormGroup.removeControl('segmentPositions');
      delete this.carrierScope?.segmentPositions;
    }
  }

  private initializeSections(): void {
    if (this.carrierScope?.carrierInformations || this.hasNDCContentType) {
      this.carrierScopeButtonGroupMap.airlineInfo.active = true;
      this.onCarrierScopeToggle(this.carrierScopeButtonGroupMap.airlineInfo);
    }

    if (this.carrierScope?.codeshareInformations) {
      this.carrierScopeButtonGroupMap.codeshare.active = true;
      this.onCarrierScopeToggle(this.carrierScopeButtonGroupMap.codeshare);

      if (this.carrierScope.codeshareInformations.primeData) {
        this.codeshareScopeButtonGroupMap.primeData.active = true;
        this.onCodeshareScopeToggle(this.codeshareScopeButtonGroupMap.primeData);
      }

      if (this.carrierScope.codeshareInformations.operatingCarrierData) {
        this.codeshareScopeButtonGroupMap.operatingCarrierData.active = true;
        this.onCodeshareScopeToggle(this.codeshareScopeButtonGroupMap.operatingCarrierData);
      }

      if (this.carrierScope.codeshareInformations.marketingCarrierData) {
        this.codeshareScopeButtonGroupMap.marketingCarrierData.active = true;
        this.onCodeshareScopeToggle(this.codeshareScopeButtonGroupMap.marketingCarrierData);
      }
    }

    if (this.carrierScope?.flightRanges) {
      this.carrierScopeButtonGroupMap.flightRanges.active = true;
      this.onCarrierScopeToggle(this.carrierScopeButtonGroupMap.flightRanges);
    }

    if (this.carrierScope?.connectionType) {
      this.carrierScopeButtonGroupMap.connectionType.active = true;
      this.onCarrierScopeToggle(this.carrierScopeButtonGroupMap.connectionType);
    }
  }
}
