<div class="result-table-container">
  <div class="d-flex justify-content-end">
    <button *ngIf="isLookup" type="button" class="btn btn-link me-auto p-2" (click)="goBack()">
      <span class="icon-angle-left df-icon-wtd me-2"></span>
      {{ goBackLinkText() }}
    </button>
    <button
      i18n="@@upp.global.button.clearTable"
      type="button"
      class="btn btn-link me-2 p-2 mb-2"
      (click)="clearTable()"
      data-test="clear-table-btn"
      >
      Clear table
    </button>
  </div>
  <div class="d-flex justify-content-end">
    <ngb-pagination
      [collectionSize]="resultsNumber"
      [(page)]="currentPage"
      [pageSize]="pageSize"
      [maxSize]="3"
      [rotate]="true"
      [ellipses]="false"
      [boundaryLinks]="true"
      [ngClass]="{ 'with-boundary-links': true }"
    ></ngb-pagination>
  </div>
  <div>
    <table class="table table-hover">
      <thead class="table-light">
        <tr>
          <th *ngIf="isLookup && !lookupOptions.singleSelect" scope="col">
            <input
              type="checkbox"
              (click)="selectAll()"
              [checked]="allSelected()"
              ngbTooltip="Select all"
              i18n-ngbTooltip="@@upp.global.text.selectAllTooltip"
              [disabled]="!isSearchResultAvailable()"
            /><ng-container i18n="@@upp.pointOfSale.search.table.include">Include</ng-container>
          </th>
          <th
            i18n="@@upp.pointOfSale.search.table.name"
            scope="col"
            class="sortable cursor-sortable"
            sortable="name"
            isSortable="true"
            (sort)="onSort($event)"
            data-test="name-column"
          >
            Name
          </th>
          <th
            [ngClass]="{'sortable cursor-sortable' : field.isSortable}"
            *ngFor="let field of displayableFields"
            attr.i18n="@@upp.pointOfSale.search.table.{{ field.value }}"
            scope="col"
            [sortable]="field.isSortable ? field.value : null"
            (sort)="onSort($event)"
            [attr.data-test]="field.value + 'Column'"
          >
            {{ field.label }}
          </th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="isSearchResultAvailable()">
        <tr
          [ngClass]="{ selected: isSelected(recordToSearchResult(result).name) }"
          *ngFor="let result of filterSuccessRecords(); index as recordIndex"
          (click)="isLookup && toggleSelection(recordToSearchResult(result).name)"
        >
          <ng-container *ngIf="recordToSearchResult(result); let searchResult">
            <td *ngIf="isLookup && !lookupOptions.singleSelect">
              <input
                type="checkbox"
                [ngModel]="isSelected(searchResult.name)"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td>{{ searchResult.name }}</td>
            <td *ngFor="let field of displayableFields">
              @if (field.value === 'ruleStatusActive') {
                <span class="form-check-label">{{ searchResult.ruleStatusActive ? 'Active' : 'Inactive' }}</span>
              }
              @else {
                <ng-container *ngFor="let value of searchResult[field.value]?.split(',')">
                  <div>
                    {{ value }}
                  </div>
                </ng-container>
              }
            </td>
            <td>
              <div class="d-flex justify-content-end">
                <div *ngIf="(!getConfirmation && !sendingStatus) || !showAlertOnCurrentRow(searchResult)">
                  <button
                    type="button"
                    class="btn btn-link me-2"
                    (click)="showDetails(searchResult.id)"
                    *ngIf="isShowEnables()"
                    [attr.data-test]="'show-btn-search-' + recordIndex"
                  >
                    @if (dynamicExclusionV2) {
                      <ng-container i18n="@@upp.global.button.display">Display</ng-container>
                    }
                    @else {
                      <ng-container i18n="@@upp.global.button.show">Show</ng-container>
                    }
                  </button>
                  <button
                    i18n="@@upp.global.button.modify"
                    type="button"
                    class="btn btn-link me-2"
                    [disabled]="!hasManageAccess(result)"
                    (click)="modifyDetails(searchResult.id)"
                    *ngIf="isModifyEnables()"
                    [attr.data-test]="'modify-btn-search-' + recordIndex"
                  >
                    Modify
                  </button>
                  <button
                    i18n="@@upp.global.button.copy"
                    type="button"
                    class="btn btn-link me-2"
                    [disabled]="!hasManageAccess(result)"
                    *ngIf="isCopyEnables()"
                    (click)="
                      askCopyConfirmation(
                        searchResult.id,
                        searchResult.name,
                        searchResult.organization,
                        searchResult.version
                      )
                    "
                    [attr.data-test]="'copy-btn-search-' + recordIndex"
                  >
                    Copy
                  </button>
                  <button
                    i18n="@@upp.global.button.search.delete"
                    type="button"
                    class="btn btn-link me-2"
                    [disabled]="!hasManageAccess(result)"
                    *ngIf="isDeleteEnables()"
                    (click)="
                      askDeleteConfirmation(
                        searchResult.id,
                        searchResult.name,
                        searchResult.organization,
                        searchResult.version
                      )
                    "
                    [attr.data-test]="'delete-btn-search-' + recordIndex"
                  >
                    Delete
                  </button>
                </div>
                <ngb-alert
                  *ngIf="getConfirmation && showAlertOnCurrentRow(searchResult)"
                  insert-alert-icon
                  [dismissible]="false"
                  type="warning"
                  class="p-3"
                >
                  <div class="ms-2">
                    <span class="icon-question-circle me-2"></span>
                    <span>{{ getConfirmationMessage() }}</span>
                    <span class="ms-2"
                      ><strong>{{ getNameForConfirmationMessage() }}</strong></span
                    >
                  </div>
                  <div class="me-4 ms-4">
                    <button
                      i18n="@@upp.global.button.abort"
                      type="button"
                      class="btn btn-primary mb-2 mb-md-0 me-2"
                      (click)="abortConfirmation()"
                      data-test="abort-confirmation-btn"
                    >
                      Abort
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary mb-2 mb-md-0"
                      (click)="executeActionAfterConfirmation()"
                      data-test="delete-confirmation-btn"
                    >
                      {{ getConfirmationActionMessage() }}
                    </button>
                  </div>
                </ngb-alert>
                <ama-loading-alert
                  [sendingStatus]="sendingStatus && this.entryToUpdateName() === searchResult.name"
                  message="Loading..."
                  i18n-message="@@upp.global.messages.loading"
                >
                </ama-loading-alert>
              </div>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!isSearchResultAvailable()" id="noResult"><strong> No result </strong></div>
    <div *ngIf="isSearchResultAvailable()" class="d-flex justify-content-start">
      <button *ngIf="isLookup" type="button" class="btn btn-link me-auto p-2" (click)="goBack()">
        <span class="icon-angle-left df-icon-wtd me-2"></span>
        {{ goBackLinkText() }}
      </button>
    </div>
    <div class="d-flex justify-content-end form-check-inline">
      <div class="input-group-sm mb-5">
        <label for="pageSize" class="form-label">Items per page: </label>
        <input
          [(ngModel)]="pageSize"
          maxlength="2"
          size="2"
          class="form-control items-per-page-input d-inline"
          id="pageSize"
          (input)="handleInvalidInput($event)"
        />
      </div>
      <ngb-pagination
        [collectionSize]="resultsNumber"
        [(page)]="currentPage"
        [pageSize]="pageSize"
        [maxSize]="3"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
        [ngClass]="{ 'with-boundary-links': true }"
      ></ngb-pagination>
    </div>
  </div>
</div>
