<div>
  <div class="d-flex justify-content-end mb-5">
    <button
      *ngIf="!readonly"
      type="button"
      class="btn btn-outline-primary btn-sm"
      (click)="clearScreen()"
      data-test="clear-button"
      i18n="@@upp.global.button.clear"
    >
      Clear
    </button>
  </div>

  <form *ngIf="flightDetailsForm" class="styled-form" [formGroup]="flightDetailsForm">
    <div formGroupName="rule">
      <div class="mb-5 form-check form-switch">
        <label class="form-check-label df-form-switch-name" for="activationToggle">
          <ng-container i18n="@@upp.flights.details.activateRuleLabel">Activate Rule</ng-container>
          <div class="df-form-switch-container">
            <input class="form-check-input" name="activationToggle" type="checkbox" id="activationToggle"
              formControlName="active" [readonly]="readonly">
            <span class="form-check-label">{{ active.value ? 'On' : 'Off' }}</span>
          </div>
        </label>
      </div>

      <div class="mb-5">
        <label for="name" class="form-label">
          <ng-container i18n="@@upp.flights.details.nameLabel">Name</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <input type="text" class="form-control" id="name" formControlName="name" [readonly]="readonly"
          [ngClass]="{ 'is-invalid': name.invalid && name.touched }"
          placeholder="Flight rule name"
          i18n-placeholder="@@upp.flights.common.nameWithSpacesPlaceholder"
          ngbTooltip="Enter the Flight Rule name with letters, numbers or spaces (up to 30 characters)."
          i18n-ngbTooltip="@@upp.flights.common.nameWithSpacesTooltip"
          oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"/>
        <ama-ng-upp-validation-error-message [control]="flightDetailsForm.get('rule.name')"
          [messageFunctions]="NAME_WITH_SPACES_VALIDATION_MESSAGES"></ama-ng-upp-validation-error-message>
      </div>

      <div class="mb-5">
        <label for="description" class="form-label" i18n="@@upp.flights.details.descriptionLabel">Description</label>
        <input type="text" class="form-control" id="description" formControlName="description"
          [ngClass]="{ 'is-invalid': !description.valid && description.touched }" [readonly]="readonly"
          placeholder="Flight rule description"
          i18n-placeholder="@@upp.flights.details.descriptionPlaceholder"
          ngbTooltip="Describe the Flight Rule (up to 128 characters)."
          i18n-ngbTooltip="@@upp.flights.details.description2Tooltip" />
        <ama-ng-upp-validation-error-message [control]="flightDetailsForm.get('rule.description')"
          [messageFunctions]="MAX_LENGTH_STRING_VALIDATION_MESSAGES"></ama-ng-upp-validation-error-message>
      </div>
    </div>
    <div class="mb-5" formGroupName="applicability">
      <div class="mb-5">
        <label for="pointOfSaleName" class="form-label">
          <ng-container i18n="@@upp.global.criteria.pointOfSale.label">Point of sale</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select id="pointOfSaleName" formControlName="pointOfSaleName"
          [items]="availablePosNames" [lookupOptions]="readonly ? null : posLookupOptions"
          notFoundText="No POS found" i18n-notFoundText="@@upp.global.criteria.pos.notFoundText">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="flightDetailsForm.get('applicability.pointOfSaleName')">
        </ama-ng-upp-validation-error-message>
      </div>
      <ng-container *ngFor="let marketPairFormGroup of marketPairsControls; let i = index;"
      [ngTemplateOutlet]="marketPair"
      [ngTemplateOutletContext]="{formGroup: marketPairFormGroup, index: i}"></ng-container>
      <div
        [ngClass]="{ 'mt-5': readonly }"
        *ngIf="showFlightsNDCCriteria" class="d-flex flex-column align-items-start mb-5">
        <label for="airContentType" class="form-label">
          <ng-container i18n="@@upp.flights.details.airContentTypeLabel">Air content type</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-checkbox-multiselect
          id="airContentType"
          [formControl]="applicability.get('contentTypes')"
          [items]="airContentTypeItems"
          (change)="onAirContentTypeChange()"
          ngbTooltip="Select one or both air content type options."
          i18n-ngbTooltip="@@upp.flights.details.airContentTypeTooltip">
        </ama-ng-upp-checkbox-multiselect>
        <ama-ng-upp-validation-error-message [control]="contentTypes"></ama-ng-upp-validation-error-message>
      </div>
      <div
        [ngClass]="{ 'mt-5': readonly && !showFlightsNDCCriteria }"
        *ngIf="!showFlightsNDCCriteria || hasEdifactContentType && !hasNDCContentType">
        <label for="products" class="form-label">
          <ng-container i18n="@@upp.flights.details.productsLabel">Product</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <ama-ng-upp-lookup-select
          id="products"
          formControlName="products"
          [items]="productItems"
          bindValue="value"
          bindLabel="label"
          [multiple]="true"
          ngbTooltip="Select one or multiple product types."
          i18n-ngbTooltip="@@upp.flights.details.productTooltip">
        </ama-ng-upp-lookup-select>
        <ama-ng-upp-validation-error-message [control]="products"></ama-ng-upp-validation-error-message>
      </div>
    </div>

    <ngb-alert dfInsertAlertIcon
      *ngIf="showWarningMessage"
      type="tip"
      [dismissible]="false"
      class="sticky-warning-msg"
      data-test="sticky-warning"
    >
      @if (hasNDCContentType) {
        <ng-container i18n="@@upp.flights.yy.message.NDC">
          If you don't select anything in the field under carrier or fare, all the options will be added to the rule.
        </ng-container>
      }
      @else {
        <ng-container i18n="@@upp.flights.yy.message.edifact">
          If you don't select anything in the field under carrier, route or journey, all the options will be added to the rule.
        </ng-container>
      }
    </ngb-alert>

    <div *ngFor="let subRuleDefinition of subRuleDefinitions; let i = index">
      <div [formGroup]="subRuleDefinition.formGroup">
        <hr *ngIf="i > 0">
        <label class="d-block mb-3">
          <ng-container i18n="@@upp.flights.details.ruleContentLabel">Rule content</ng-container>
          <span class="mandatory ms-1">*</span>
        </label>
        <div class="mb-5">
          <div class="d-flex align-items-center">
            <ama-ng-upp-button-group [items]="subRuleDefinition.ruleContentButtonGroupItems"
              (toggled)="onRuleContentToggle($event, subRuleDefinition)">
            </ama-ng-upp-button-group>
            <ng-container *ngIf="!readonly">
              <button (click)="addSubRule(i + 1)" type="button"
                class="btn btn-outline-primary btn-sm df-btn-icononly icon-plus ms-2"
                aria-label="Add sub rule" i18n-aria-label="@@upp.flights.button.addSubRule.ariaLabel" ></button>
              <button *ngIf="subRules.controls.length > 1" (click)="removeSubRule(i)" type="button"
                class="btn btn-outline-primary btn-sm df-btn-icononly icon-trash-alt df-icon-solid ms-2"
                aria-label="Delete sub rule" i18n-aria-label="@@upp.flights.button.deleteSubRule.ariaLabel"></button>
            </ng-container>
          </div>
          <ama-ng-upp-validation-error-message [control]="subRuleDefinition.formGroup.get('hasRuleContent')"
            [messageFunctions]="SECTION_VALIDATION_MESSAGES"></ama-ng-upp-validation-error-message>
        </div>
        <div class="carrier-content-container" *ngIf="subRuleDefinition.ruleContentButtonGroupMap.carrier?.active">
          <ama-ng-upp-flight-details-carrier
            [carrierFormGroup]="subRuleDefinition.formGroup.get('applicability.carrier')"
            [carrierScope]="subRuleDefinition.subRule?.applicability.carrier"
            [flightDetailsId]="flightDetails.rule?.id"
            [hasNDCContentType]="hasNDCContentType"
            [showFamiliesV2]="showFamiliesV2"
            [readonly]="readonly">
          </ama-ng-upp-flight-details-carrier>
        </div>
        <div class="route-content-container" *ngIf="subRuleDefinition.ruleContentButtonGroupMap.route?.active">
          <ama-ng-upp-route-content
            [routeFormGroup]="subRuleDefinition.formGroup.get('applicability.route')"
            [routeScope]="subRuleDefinition.subRule?.applicability.route"
            [parentSubruleIndex]="i"
            [hasNDCContentType]="hasNDCContentType"
            [readonly]="readonly">
          </ama-ng-upp-route-content>
        </div>
        <div class="journey-content-container" *ngIf="subRuleDefinition.ruleContentButtonGroupMap.journey?.active">
          <ama-ng-upp-journey-content
            [journeyFormGroup]="subRuleDefinition.formGroup.get('applicability.journey')"
            [journeyScope]="subRuleDefinition.subRule?.applicability.journey"
            [readonly]="readonly">
          </ama-ng-upp-journey-content>
        </div>
        <div class="fare-content-container" *ngIf="subRuleDefinition.ruleContentButtonGroupMap.fare?.active">
          <ama-ng-upp-fare-content
            [fareFormGroup]="subRuleDefinition.formGroup.get('applicability.fare')"
            [fareScope]="subRuleDefinition.subRule?.applicability.fare"
            [readonly]="readonly">
          </ama-ng-upp-fare-content>
        </div>
        <ama-ng-upp-rule-action-or-value formControlName="content" [allowSorting]="!hasNDCContentType">
        </ama-ng-upp-rule-action-or-value>
        <ama-ng-upp-validation-error-message [control]="subRuleDefinition.formGroup.get('content')"
          [messageFunctions]="contentValidationMessages"></ama-ng-upp-validation-error-message>
      </div>
    </div>
  </form>
</div>

<ng-template #marketPair let-formGroup="formGroup" let-index="index">
  <div [id]="'marketPair' + index" class="d-flex align-items-start" [formGroup]="formGroup">
    <div class="flex-basis-100">
      <label [for]="'firstMarketName' + index" class="form-label">
        <ng-container i18n="@@upp.global.criteria.firstMarket.label">First market</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-lookup-select [id]="'firstMarketName' + index" formControlName="firstMarketName"
        [items]="availableMarketsNames" [lookupOptions]="readonly ? null : getMarketLookupOptions()"
        notFoundText="No markets found" i18n-notFoundText="@@upp.global.criteria.markets.notFoundText">
      </ama-ng-upp-lookup-select>
      <ama-ng-upp-validation-error-message [control]="formGroup.get('firstMarketName')">
      </ama-ng-upp-validation-error-message>
    </div>

    <div class="flex-basis-100 ms-5">
      <label [for]="'secondMarketName' + index" class="form-label">
        <ng-container i18n="@@upp.global.criteria.secondMarket.label">Second market</ng-container>
        <span class="mandatory ms-1">*</span>
      </label>
      <ama-ng-upp-lookup-select [id]="'secondMarketName' + index" formControlName="secondMarketName"
        [items]="availableMarketsNames" [lookupOptions]="readonly ? null : getMarketLookupOptions()"
        notFoundText="No markets found" i18n-notFoundText="@@upp.global.criteria.markets.notFoundText">
      </ama-ng-upp-lookup-select>
      <ama-ng-upp-validation-error-message [control]="formGroup.get('secondMarketName')">
      </ama-ng-upp-validation-error-message>
    </div>

    <ng-container *ngIf="!readonly">
      <button (click)="addBidirectionalMarketPair(index + 1, formGroup.value)" type="button"
        class="btn btn-outline-primary mt-7 mb-5 ms-5 flex-grow-auto" aria-label="Add bidirectional market pair"
        i18n-aria-label="@@upp.flights.button.addBidirectionalMarketPair.ariaLabel"
        [disabled]="marketPairsControls.length >= MAX_NUMBER_OF_MARKET_PAIRS"
        i18n="@@upp.flights.button.bidirectional" [id]="'addBidirectionalPair' + index">Bidirectional</button>
      <button (click)="addMarketPair(index + 1)" type="button"
        class="btn btn-outline-primary df-btn-icononly icon-plus mt-7 mb-5 ms-2 flex-grow-auto"
        aria-label="Add market pair" i18n-aria-label="@@upp.flights.button.addMarketPair.ariaLabel"
        [disabled]="marketPairsControls.length >= MAX_NUMBER_OF_MARKET_PAIRS"
        [id]="'addPair' + index"></button>
      <button *ngIf="marketPairsControls.length > 1" (click)="removeMarketPair(index)" type="button"
        class="btn btn-outline-primary df-btn-icononly icon-trash-alt df-icon-solid mt-7 mb-5 ms-2 flex-grow-auto"
        aria-label="Delete market pair" i18n-aria-label="@@upp.flights.button.deleteMarketPair.ariaLabel"
        [id]="'deletePair' + index"></button>
    </ng-container>
  </div>

  <ama-ng-upp-validation-error-message class="duplicate-error-msg" [control]="formGroup"
    [messageFunctions]="marketPairsValidationMessages"></ama-ng-upp-validation-error-message>
</ng-template>
